import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Marker as MarkerType } from '@/typings/model';
import BlueIcon from '@/assets/svg/icon_map_blue.svg';
import CloseIcon from '@/assets/svg/black.svg';

interface Props extends MarkerType {
  lat?: number;
  lng?: number;
}

const Marker = ({ name, address }: Props) => {
  const [isPopupOpened, setPopupOpened] = useState(false);

  const handlePopupOpen = (event: React.SyntheticEvent) => {
    setPopupOpened(!isPopupOpened);
    event.stopPropagation();
  };

  return (
    <Component>
      <StyledBlueIcon onClick={handlePopupOpen} />
      <Popup isPopupOpened={isPopupOpened}>
        <StyledCloseIcon onClick={handlePopupOpen} />
        <Name>{name}</Name>
        <Address>{address}</Address>
      </Popup>
    </Component>
  );
};

export default Marker;

const Component = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

const Popup = styled.div<{
  isPopupOpened: boolean;
}>`
  z-index: 100;
  position: absolute;
  min-width: 250px;
  bottom: 55px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 12px 12px;
  border-radius: 8px;
  box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
  margin-bottom: 10px;
  background: ${(props) => props.theme.white};
  visibility: hidden;

  &:after {
    content: '';
    background: ${(props) => props.theme.white};
    box-shadow: -2px 2px 2px 0 rgb(178 178 178 / 40%);
    height: 15px;
    position: absolute;
    left: 50%;
    bottom: -13px;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 15px;
  }

  ${({ isPopupOpened }) =>
    isPopupOpened &&
    css`
      visibility: visible;
    `}
`;

const Name = styled.h2`
  color: ${(props) => props.theme.black};
`;

const Address = styled.span`
  font-weight: 300;
  font-size: 13px;
  margin-top: 16px;
  color: ${(props) => props.theme.black};
`;

const StyledBlueIcon = styled(BlueIcon)`
  top: -50px;
  left: -20px;
  position: absolute;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  opacity: 0.6;
  width: 14px;
  height: 14px;
  top: 2px;
  right: 2px;
  fill: ${(props) => props.theme.black};

  &:hover {
    opacity: 1;
  }
`;
