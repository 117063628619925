import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import Link from '@/components/Link';
import FacebookIcon from '@/assets/svg/socials/facebook.svg';
import VKIcon from '@/assets/svg/socials/vk.svg';
import InstagramIcon from '@/assets/svg/socials/instagram.svg';
import OKIcon from '@/assets/svg/socials/ok.svg';
import YoutubeIcon from '@/assets/svg/socials/youtube.svg';
import TelegramIcon from '@/assets/svg/socials/tlgrm.svg';
import ViberIcon from '@/assets/svg/socials/viber.svg';
import SkypeIcon from '@/assets/svg/socials/skype.svg';
import { media } from '@/utils/mixin';
import { getPhoneLink } from '@/utils/common';

interface Props {
  title: string;
  address?: string;
  phones?: string[];
  socialYoutube?: string;
  socialInstagram?: string;
  socialOdnoklassnikiUrl?: string;
  socialFacebookUrl?: string;
  socialVkontakteUrl?: string;
  socialTelegramUrl?: string;
  socialViberUrl?: string;
  socialSkypeUrl?: string;
  divisions?: string[];
}

function BodyCell({
  title,
  address,
  divisions,
  phones,
  socialFacebookUrl,
  socialVkontakteUrl,
  socialInstagram,
  socialOdnoklassnikiUrl,
  socialYoutube,
  socialTelegramUrl,
  socialViberUrl,
  socialSkypeUrl,
}: Props) {
  return (
    <Component>
      <Title>{title}</Title>
      {address && <Text>{address}</Text>}

      {divisions && divisions.length !== 0 && (
        <Wrapper>
          {divisions.map((division, index) => (
            <Text key={index}>{division}</Text>
          ))}
        </Wrapper>
      )}

      {phones && phones.length !== 0 && (
        <Wrapper>
          {phones.map((phone, index) => (
            <Phone key={index} to={getPhoneLink(phone)}>
              {phone}
            </Phone>
          ))}
        </Wrapper>
      )}

      {(socialFacebookUrl ||
        socialVkontakteUrl ||
        socialInstagram ||
        socialOdnoklassnikiUrl ||
        socialYoutube ||
        socialTelegramUrl ||
        socialViberUrl ||
        socialSkypeUrl) && (
        <Socials>
          {socialFacebookUrl ? (
            <Social to={socialFacebookUrl} target="_blank">
              <FacebookIcon />
            </Social>
          ) : null}
          {socialVkontakteUrl ? (
            <Social to={socialVkontakteUrl} target="_blank">
              <VKIcon />
            </Social>
          ) : null}
          {socialInstagram ? (
            <Social to={socialInstagram} target="_blank">
              <InstagramIcon />
            </Social>
          ) : null}
          {socialOdnoklassnikiUrl ? (
            <Social to={socialOdnoklassnikiUrl} target="_blank">
              <OKIcon />
            </Social>
          ) : null}
          {socialYoutube ? (
            <Social to={socialYoutube} target="_blank">
              <YoutubeIcon />
            </Social>
          ) : null}
          {socialTelegramUrl ? (
            <Social to={socialTelegramUrl} target="_blank">
              <TelegramIcon />
            </Social>
          ) : null}
          {socialViberUrl ? (
            <Social to={socialViberUrl} target="_blank">
              <ViberIcon />
            </Social>
          ) : null}
          {socialSkypeUrl ? (
            <Social to={socialSkypeUrl} target="_blank">
              <SkypeIcon />
            </Social>
          ) : null}
        </Socials>
      )}
    </Component>
  );
}

export default BodyCell;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 0.625rem;
  margin-bottom: 0.75rem;

  ${media.tabletSmall(css`
    margin-bottom: 0.625rem;
  `)}

  ${media.mobile(css`
    width: auto;
    padding: 0;
    margin-bottom: 10px;
  `)}
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.blue200};
`;

const Text = styled.span`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${(props) => props.theme.blue100};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Phone = styled(Link)`
  position: relative;
  color: ${colors.main};
  border-bottom: 1px solid transparent;

  &:hover {
    border-color: ${colors.main};
  }
`;

const Socials = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;

const Social = styled(Link)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background: #707c91;
  margin: 0.125rem;

  &:hover {
    background: ${colors.main};
  }
`;
