import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment/moment';

import { Nullable } from '@tager/web-core';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  fetchDealerReviewsThunk,
  selectDealerReviews,
} from '@/store/reducers/single-dealer';
import {
  DealerReviews as DealerReviewsType,
  DealerReviewsSortType,
} from '@/typings/model';
import { media } from '@/utils/mixin';
import ReviewCard from '@/components/DealerReviews/components/ReviewCard';
import Button from '@/components/Button';
import {
  defaultReviewsLimit,
  defaultReviewsSort,
} from '@/modules/Dealers/pages/Dealer/constants';
import { SortSelect } from '@/components/SortSelect';
import { OptionType } from '@/typings/common';

import { DealerReviewsProps } from './types';
import { sortingData } from './constants';

function DealerReviews({
  dealerId,
  limit = defaultReviewsLimit,
  options = { sort: { defaultSort: defaultReviewsSort } },
}: DealerReviewsProps) {
  const dispatch = useTypedDispatch();
  const reviews = useTypedSelector(selectDealerReviews);

  const [currentSort, setCurrentSort] = useState<
    OptionType<DealerReviewsSortType>
  >(
    sortingData.find((item) => item.value === defaultReviewsSort) ??
      sortingData[0]
  );
  const [reviewsData, setReviewsData] =
    useState<Nullable<DealerReviewsType>>(null);

  useEffect(() => {
    setReviewsData(reviews);
  }, []);

  if (!reviewsData || !reviewsData.data || reviewsData.data.length === 0) {
    return null;
  }

  const handleShowMoreReviews = () => {
    try {
      dispatch(
        fetchDealerReviewsThunk(dealerId, {
          sort: currentSort.value,
          offset: reviewsData.data.length,
          limit,
        })
      ).then((reviews) =>
        setReviewsData({
          ...reviewsData,
          data: [...reviewsData.data, ...reviews.data],
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeSort = (newSort: OptionType<DealerReviewsSortType>) => {
    setCurrentSort(newSort);

    try {
      dispatch(
        fetchDealerReviewsThunk(dealerId, {
          sort: newSort.value,
          offset: 0,
          limit,
        })
      ).then((reviews) => {
        setReviewsData(reviews);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Component>
      {!options?.sort?.disabled && (
        <SortSelectWrapper>
          <SortSelect
            title=""
            value={currentSort}
            options={sortingData}
            onChange={handleChangeSort}
          />
        </SortSelectWrapper>
      )}

      <Reviews>
        {reviewsData.data.map(
          ({ rating, name, date, like, dislike, answer }, index) => (
            <ReviewCard
              key={index}
              rating={options.withoutRating ? undefined : rating}
              name={name ?? ''}
              date={date ? moment(date).format('DD MMMM YYYY') : ''}
              text={like ?? dislike ?? ''}
              answer={options.withoutAnswer ? undefined : answer ?? ''}
            />
          )
        )}
      </Reviews>

      {reviewsData.data.length !== reviewsData.total && (
        <ButtonWrapper>
          <StyledButton variant="outlined" onClick={handleShowMoreReviews}>
            Показать еще
          </StyledButton>
        </ButtonWrapper>
      )}
    </Component>
  );
}

export default DealerReviews;

const Component = styled.div``;

const SortSelectWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const Reviews = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  ${media.tablet(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const StyledButton = styled(Button)`
  max-width: 240px;
`;
