import React, { useState } from 'react';
import styled from 'styled-components';

import Rating from '@/components/Rating';
import { colors } from '@/constants/theme';

import { ReviewCardProps } from './types';

function ReviewCard({ rating, name, date, text, answer }: ReviewCardProps) {
  const [isTextOpened, setTextOpened] = useState(false);

  const isNotFullText = text && text.length > 120 && !isTextOpened;

  return (
    <Component>
      {rating && (
        <RatingWrapper>
          <Rating value={rating} count={5} />
        </RatingWrapper>
      )}
      <Name>{name}</Name>
      <Date>{date}</Date>
      {text && (
        <Text>
          {text.substring(0, isNotFullText ? 120 : undefined)}
          {isNotFullText && '...'}
          {isNotFullText && (
            <button onClick={() => setTextOpened(true)}>Показать еще</button>
          )}
        </Text>
      )}
      {answer && (
        <Answer>
          <b>Ответ автоцентра:</b> {answer}
        </Answer>
      )}
    </Component>
  );
}

export default ReviewCard;

const Component = styled.div`
  padding: 20px;
  background: ${(props) => props.theme.white_1};
  border: 1px solid ${(props) => props.theme.gray1000_1};
  border-radius: 10px;
`;

const RatingWrapper = styled.div`
  margin-bottom: 10px;
`;

const Name = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.black};
`;

const Date = styled.p`
  font-size: 14px;
  line-height: 16px;
  margin-top: 5px;
  color: ${colors.gray500};
`;

const Text = styled.p`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  line-height: 19px;
  margin-top: 20px;
  color: ${(props) => props.theme.black};

  button {
    color: ${colors.main};
    transition: 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const Answer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 19px;
  margin-top: 20px;
  color: ${(props) => props.theme.black};
`;
