import React from 'react';
import styled, { css } from 'styled-components';

import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';

import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import Rating from '@/components/Rating';

import BodyTable from './components/BodyTable';
import { BodyTableProps } from './components/BodyTable/types';

interface Props {
  url?: string;
  name: string;
  legalName: string;
  rating?: number;
  logos?: ThumbnailType[];
  locations: BodyTableProps[];
}

function DealerCard({ url, name, legalName, rating, logos, locations }: Props) {
  return (
    <Component>
      {url && <CardLink to={url} />}
      <Header>
        <Main>
          <Top>
            <Name>{name}</Name>
            {rating ? (
              <RateWrapper>
                <Rating value={rating} count={5} />
              </RateWrapper>
            ) : null}
          </Top>
          <Bottom>
            <LegalName>{legalName}</LegalName>
          </Bottom>
        </Main>
        <Logos>
          {logos &&
            logos.length !== 0 &&
            logos.map(
              (logo, index) =>
                logo && (
                  <Logo
                    key={index}
                    loading="lazy"
                    {...convertThumbnailToPictureProps(logo)}
                  />
                )
            )}
        </Logos>
      </Header>
      <Body>
        <BodyList>
          {locations &&
            locations.length !== 0 &&
            locations.map((location, index) => (
              <BodyTable key={index} {...location} />
            ))}
        </BodyList>
      </Body>
    </Component>
  );
}

export default DealerCard;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 47%;
  max-width: 47%;
  box-shadow: 0 0.313rem 1.875rem 0 rgb(23 43 77 / 10%);

  ${media.mobile(css`
    flex: auto;
    max-width: none;
    box-shadow: none;
    border-bottom: 1px solid rgba(23, 43, 77, 0.1);
  `)}

  &:not(:first-child) {
    margin-top: 30px;

    ${media.mobile(css`
      margin-top: 0;
    `)}
  }
`;

const CardLink = styled(Link)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid ${colors.white};

  &:hover {
    border-color: ${colors.main};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 8px;
  background: ${(props) => props.theme.white};

  ${media.tabletSmall(css`
    padding: 20px 20px 8px;
  `)}
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const Top = styled.div`
  display: flex;
  align-items: center;

  ${media.tabletSmall(css`
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

const Name = styled.p`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${colors.main};

  ${media.tabletSmall(css`
    font-size: 1.25rem;
    line-height: 1.875rem;
  `)}
`;

const RateWrapper = styled.div`
  margin-left: 0.625rem;

  ${media.tabletSmall(css`
    margin-top: 12px;
    margin-left: 0;
  `)}
`;

const Bottom = styled.div`
  ${media.tabletSmall(css`
    margin-top: 18px;
  `)}
`;

const LegalName = styled.p`
  font-size: 0.875rem;
  line-height: 1.25rem;
  max-width: 400px;
  color: ${(props) => props.theme.blue100};
`;

const Logos = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-left: 10px;
`;

const Logo = styled(Picture)`
  flex: 0 0 45px;
  width: 45px;
  height: 45px;
  padding: 2px;
  border-radius: 10px;
  background: ${colors.white};

  &:nth-child(odd):last-child {
    grid-column-start: 2;
  }

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: contain;
  }
`;

const Body = styled.div`
  ${media.mobile(css`
    margin: 10px 0;
  `)}
`;

const BodyList = styled.div``;
