import { OptionType } from '@/typings/common';
import { DealerReviewsSortType } from '@/typings/model';

export const sortingData: OptionType<DealerReviewsSortType>[] = [
  {
    label: 'С высоким рейтингом',
    value: 'rating_desc',
  },
  {
    label: 'С низким рейтингом',
    value: 'rating_asc',
  },
  {
    label: 'Сначала новые',
    value: 'date_desc',
  },
  {
    label: 'Сначала старые',
    value: 'date_asc',
  },
];
